import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { useIsMobile, useProfileWithCompletedSteps } from "@app.automotus.io/components/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_CURRENTLY_REGISTERED_VEHICLES,
  GetCurrentlyRegisteredVehiclesData,
  GetCurrentlyRegisteredVehiclesVars,
} from "common/graphql/queries/GetCurrentlyRegisteredVehicles";
import NeedHelp from "../AddVehicle/NeedHelp";
import OnboardingInformation from "@app.automotus.io/components/OnboardingInformation/OnboardingInformation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatCurrency } from "common/format";
import {
  GET_DEFAULT_PAYMENT_METHOD_WITH_PAYEE,
  GetDefaultPaymentMethodWithPayeeData,
  GetDefaultPaymentMethodWithPayeeVars,
  PaymentMethod,
} from "common/graphql";
import { useActivePayee } from "../../../hooks/useActivePayee";
import useActiveWallet from "../../../hooks/useActiveWallet";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { appName } from "common/constants";

export const FinishSignUp: React.FC<FinishSignUpProps> = ({
  onClickFinishSignUp,
  finishingSignUp = false,
  isBillByMail = false,
}) => {
  const { loading, completedSteps } = useProfileWithCompletedSteps();
  const { userProfile } = useUserProfile();
  const { data } = useQuery<GetCurrentlyRegisteredVehiclesData, GetCurrentlyRegisteredVehiclesVars>(
    GET_CURRENTLY_REGISTERED_VEHICLES,
  );
  const registeredVehicles = data?.registeredVehicles || [];
  const { activePayee } = useActivePayee();
  const { wallet } = useActiveWallet();
  const [getPaymentMethod, { data: paymentMethodData }] = useLazyQuery<
    GetDefaultPaymentMethodWithPayeeData,
    GetDefaultPaymentMethodWithPayeeVars
  >(GET_DEFAULT_PAYMENT_METHOD_WITH_PAYEE);

  useEffect(() => {
    const abortController = new AbortController();

    if (activePayee) {
      (async () => {
        await getPaymentMethod({
          variables: activePayee,
          context: { fetchOptions: { signal: abortController.signal } },
        });
      })();
    }

    return () => {
      abortController.abort();
    };
  }, [activePayee, getPaymentMethod]);

  const theme = useTheme();
  const isMobile = useIsMobile();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));

  const getPaymentMethodDisplay = (paymentMethod: PaymentMethod) => {
    // TODO: Need to update the message when we have more information.
    if (paymentMethod.type === "credit_card") {
      return `Card (${paymentMethod.maskedNumber})`;
    } else {
      return "Bank";
    }
  };

  const [paymentMethod] = paymentMethodData?.paymentMethod || [];
  const isAirportAccount =
    activePayee?.payeeAccountId && activePayee.payeeAccountId === process.env.REACT_APP_GSP_AIRPORT_ACCOUNT_ID;

  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ flexGrow: 1, mt: isMobile ? 1.5 : 3, px: isMobile ? 0 : 2 }}>
      {isBillByMail && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems="center"
          sx={{ backgroundColor: "rgba(76, 175, 80, 0.08)", padding: "16px", color: "#3A833C", mb: 3 }}
        >
          <CheckCircleOutline sx={{ width: 20, height: 20, p: "2px", mr: "12px" }} />
          <Typography variant={"body1"}>
            Your invoice has been paid! We’ve sent a confirmation to your email.
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant={isDesktop ? "h4" : isSmall ? "h6" : "h5"} sx={{ fontWeight: 500 }}>
          You're all set
        </Typography>
        <Typography variant={"body1"}>CurbPass will auto-charge your account based on your curb use.</Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Stack direction={"column"} spacing={0.5}>
          <OnboardingInformation
            title="Profile Information"
            content={userProfile?.fullName || ""}
            subContent={userProfile?.email || ""}
          />
          <OnboardingInformation title="Registered Vehicles" content={`${registeredVehicles.length}`} />
          {isAirportAccount ? (
            <OnboardingInformation
              title="Payment Method"
              content={paymentMethod ? getPaymentMethodDisplay(paymentMethod) : ""}
            />
          ) : (
            <OnboardingInformation
              title="CurbPass Balance"
              subTitle={
                userProfile?.account.isFreeTrial
                  ? "Auto refills after your next park"
                  : `Auto refills when your balance reaches $${formatCurrency(wallet?.minWalletBalance || 0)}`
              }
              content={
                userProfile?.account.isFreeTrial
                  ? "Free trial"
                  : (wallet?.desiredWalletBalance || 0) > 0
                  ? `$${formatCurrency(wallet?.desiredWalletBalance || 0)}`
                  : "$10"
              }
              subContent={
                paymentMethod
                  ? userProfile?.account.isFreeTrial
                    ? `${getPaymentMethodDisplay(paymentMethod)}`
                    : `Paid with ${getPaymentMethodDisplay(paymentMethod)}`
                  : ""
              }
            />
          )}
        </Stack>
      </Box>
      <Box sx={{ display: "flex", flex: 1 }} />
      <Box sx={{ mt: 3, mb: { xs: 2, lg: 0 } }}>
        <LoadingButton
          loading={finishingSignUp}
          variant={"contained"}
          size={isSmall ? "medium" : "large"}
          fullWidth
          onClick={onClickFinishSignUp}
          disabled={loading || completedSteps.length !== 3}
          sx={{
            textTransform: "unset",
            py: { xs: 0.75, tiny: 1, lg: 1 },
            height: { xs: "30px", tiny: "42px", lg: "42px" },
          }}
        >
          Go to Dashboard
        </LoadingButton>
      </Box>
      {isMobile && appName !== "curbsuite.io" && (
        <Box sx={{ mb: 1.25 }}>
          <NeedHelp />
        </Box>
      )}
    </Box>
  );
};

export interface FinishSignUpProps {
  onClickFinishSignUp?: React.MouseEventHandler<HTMLButtonElement>;
  finishingSignUp?: boolean;
  isBillByMail?: boolean;
}

export default FinishSignUp;
