import { gql } from "@apollo/client";
import { OrderBy } from "common/graphql/queryString";
import { CitationSummary, citationSummaryFragment } from "common/graphql";

export const GET_CITATIONS = gql`
  query GetCitations(
    $limit: Int!
    $offset: Int!
    $filter: enforcement_citation_bool_exp = { notice_sent_at: { _is_null: false } }
    $orderBy: [enforcement_citation_order_by!]
  ) {
    citations: enforcement_citation(where: $filter, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...citationSummaryFragment
    }
    citationsAggregate: enforcement_citation_aggregate(where: { notice_sent_at: { _is_null: false } }) {
      aggregate {
        count
        sum {
          paidAmount: paid_amount
        }
      }
    }
  }
  ${citationSummaryFragment}
`;

export interface GetCitationsWhereFilter {
  notice_sent_at: { _is_null: false };
  citation_number?: { _eq: string };
  session_transaction?: { registered_vehicle: { license_plate: { _eq: string } } };
  violation_type?: { _eq: string };
  paid_at?: { _is_null: boolean };
  due_at?: { _lt: Date };
  sent_to_collections_at?: { _is_null: boolean };
  voided_at?: { _is_null: boolean };
}

export interface GetCitationsVars {
  limit: number;
  offset: number;
  orderBy?: CitationOrderBy[];
  filter?: GetCitationsWhereFilter;
}

export type CitationOrderBy = CitationOrderByIndex | CitationOrderByNoticeDate | CitationOrderByPaidDate;

export interface CitationOrderByIndex {
  citation_number: OrderBy;
}

export interface CitationOrderByNoticeDate {
  notice_sent_at: OrderBy;
}

export interface CitationOrderByPaidDate {
  paid_at: OrderBy;
}

export interface GetCitationsData {
  citations: CitationSummary[];
  citationsAggregate: {
    aggregate: {
      count: number;
      sum: {
        paidAmount: number;
      };
    };
  };
}
