import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  getGridStringOperators,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterModel,
  GridSortModel,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useQuery } from "@apollo/client";
import { TableErrorOverlay } from "@app.automotus.io/components/PayeeTables/TableErrorOverlay";
import { TableLoadingOverlay } from "@app.automotus.io/components/PayeeTables/TableLoadingOverlay";
import { formatCurrency } from "common/format";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import format from "date-fns/format";
import {
  CitationOrderBy,
  CitationSummary,
  GET_CITATIONS,
  GetCitationsData,
  GetCitationsVars,
  GetCitationsWhereFilter,
} from "common/graphql";
import { parseSortModel, serializeSortModel } from "common/graphql/queryString";
import { getViolationTypeLabel, violationTypes } from "./utils";
import { Theme } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export interface CitationsLocationState {
  citationTablePage?: number;
  citationTablePageSize?: number;
  citationTableSortStr?: string;
}

export const Citations: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
    pageSize: "50",
  });
  const navigate = useNavigate();

  const page = Number(searchParams.get("page") || 1);
  const handlePageChange = (newPage: number) => {
    searchParams.set("page", `${newPage}`);
    setSearchParams(searchParams);
  };

  const pageSize = Number(searchParams.get("pageSize") || 50);
  const handlePageSizeChange = (newPageSize: number) => {
    searchParams.set("pageSize", `${newPageSize}`);
    setSearchParams(searchParams);
  };

  const sortStr = searchParams.get("sort") || "noticeDate+desc";
  const sortModel: GridSortModel = parseSortModel(sortStr);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    searchParams.set("sort", serializeSortModel(newSortModel));
    setSearchParams(searchParams);
  };

  const handleNavigate = (id: string) => {
    navigate(id, {
      state: {
        citationTablePage: page,
        citationTablePageSize: pageSize,
        citationTableSortStr: sortStr,
      },
    });
  };

  return (
    <Stack spacing={3} width={"100%"}>
      <Box>
        <Typography variant={"h4"} fontWeight={600} letterSpacing={0.25}>
          Citations
        </Typography>
        <Typography variant={"subtitle1"} fontSize={"12px"} letterSpacing={0.4} color={"text.disabled"}>
          Recent transactions can take up to 24 hours to process.
        </Typography>
      </Box>
      <CitationTable
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onNavigate={handleNavigate}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />
    </Stack>
  );
};

export const CitationTableToolbar: React.FC<CitationTableToolbarProps> = ({ exportFileName }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      height={52}
      px={1.25}
      bgcolor={(theme: Theme) => theme.palette.background.paper}
      spacing={1}
    >
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: exportFileName }}
        excelOptions={{ fileName: exportFileName }}
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          letterSpacing: 0.15,
          textTransform: "none",
        }}
      />
    </Stack>
  );
};

const CitationStatusFilterValue: React.FC<GridFilterInputValueProps> = ({ item, applyValue }) => {
  const handleChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value as string });
  };

  return (
    <FormControl variant="standard">
      <InputLabel id="status-filter-select-label">Status</InputLabel>
      <Select
        labelId="status-filter-select-label"
        id="status-filter-select"
        value={item.value || ""}
        label="Status"
        onChange={handleChange}
      >
        <MenuItem value={""}>Any</MenuItem>
        <MenuItem value={"Paid"}>Paid</MenuItem>
        <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
        <MenuItem value={"Overdue"}>Overdue</MenuItem>
        <MenuItem value={"Sent to Collections"}>Sent to Collections</MenuItem>
        <MenuItem value={"Void"}>Void</MenuItem>
      </Select>
    </FormControl>
  );
};

const ViolationTypeFilterValue: React.FC<GridFilterInputValueProps> = ({ item, applyValue }) => {
  const handleChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value as string });
  };

  return (
    <FormControl variant="standard">
      <InputLabel id="violation-type-filter-select-label">Violation Type</InputLabel>
      <Select
        labelId="violation-type-filter-select-label"
        id="violation-type-filter-select"
        value={item.value || ""}
        label="Violation Type"
        onChange={handleChange}
      >
        <MenuItem value={""}>Any</MenuItem>
        {violationTypes.map((violationType) => (
          <MenuItem key={violationType.value} value={violationType.value}>
            {violationType.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export interface CitationTableToolbarProps {
  exportFileName: string;
}

export const CitationTable: React.FC<CitationTableProps> = ({
  page,
  onPageChange,
  pageSize,
  onPageSizeChange,
  onNavigate,
  sortModel,
  onSortModelChange,
}) => {
  const CITATION_TABLE_COLUMNS = useMemo(
    () => [
      {
        field: "citationNumber",
        headerName: "Citation ID",
        width: 150,
        align: "left",
        headerAlign: "left",
        sortingOrder: ["desc", "asc"],
        filterable: true,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === "equals"),
      } as GridColDef<CitationSummary, string>,
      {
        field: "licensePlate",
        headerName: "License Plate",
        width: 150,
        valueGetter({ row }) {
          return row?.sessionTransaction?.registeredVehicle
            ? `${row.sessionTransaction.registeredVehicle.licensePlateStateCode} ${row.sessionTransaction.registeredVehicle.licensePlateNumber}`
            : "N/A";
        },
        sortable: false,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === "equals"),
      } as GridColDef<CitationSummary, string>,
      {
        field: "violationType",
        headerName: "Violation Type",
        width: 150,
        sortable: false,
        valueFormatter({ value }) {
          return getViolationTypeLabel(value);
        },
        filterable: true,
        filterOperators: getGridStringOperators()
          .filter((operator) => operator.value === "equals")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent ? ViolationTypeFilterValue : undefined,
          })),
      } as GridColDef<CitationSummary, string>,
      {
        field: "noticeDate",
        headerName: "Notice Date",
        type: "date",
        width: 159,
        valueGetter({ row }) {
          return new Date(row.noticeSentAt);
        },
        sortingOrder: ["desc", "asc"],
        filterable: false,
      } as GridColDef<CitationSummary, Date>,
      {
        field: "paidDate",
        headerName: "Paid Date",
        type: "date",
        width: 138,
        valueGetter({ row }) {
          return row.paidAt ? new Date(row.paidAt) : null;
        },
        valueFormatter({ value }) {
          return value ? format(value, "MM/dd/yyyy") : "-";
        },
        sortingOrder: ["desc", "asc"],
        filterable: false,
      } as GridColDef<CitationSummary, Date | null, string>,
      {
        field: "status",
        headerName: "Status",
        width: 122,
        valueGetter({ row }) {
          if (row.paidAt) {
            return "Paid";
          } else if (row?.dispute?.status === "pending") {
            return "Under Review";
          } else if (row.voidedAt) {
            return "Void";
          } else if (new Date(row.dueAt).getTime() < Date.now()) {
            return "Overdue";
          } else {
            return "Pending";
          }
        },
        sortable: false,
        filterable: true,
        filterOperators: getGridStringOperators()
          .filter((operator) => operator.value === "equals")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent ? CitationStatusFilterValue : undefined,
          })),
      } as GridColDef<CitationSummary, string>,
      {
        field: "amount",
        headerName: "Amount",
        width: 100,
        sortable: false,
        valueGetter({ row }) {
          return row.totalAmountDue;
        },
        valueFormatter({ value }) {
          return `$${formatCurrency(value)}`;
        },
        filterable: false,
      } as GridColDef<CitationSummary, number, string>,
      {
        field: "actions",
        type: "actions",
        sortable: false,
        getActions: (params) => [
          <GridActionsCellItem
            label={"View Citation"}
            icon={<ArrowForwardIosIcon sx={{ color: (theme: Theme) => theme.palette.action.active }} />}
            onClick={() => onNavigate(`${params.row.id}`)}
          />,
        ],
      } as GridActionsColDef,
    ],
    [onNavigate],
  );
  const offset = page * pageSize;
  const limit = pageSize;

  const parseOrderBy = (sortModel: GridSortModel): CitationOrderBy[] => {
    const { field: sortField, sort: sortDirection } = sortModel[0];
    switch (sortField) {
      case "citationNumber":
        return [{ citation_number: sortDirection || "asc" }];
      case "paidDate":
        return [{ paid_at: sortDirection === "asc" ? "asc_nulls_last" : "desc_nulls_last" }];
      default:
        return [{ notice_sent_at: sortDirection || "desc" }];
    }
  };

  const [filter, setFilter] = React.useState<GetCitationsWhereFilter>({ notice_sent_at: { _is_null: false } });
  const onFilterChange = React.useCallback((newFilterModel: GridFilterModel) => {
    const newFilter: GetCitationsWhereFilter = newFilterModel.items.reduce(
      (acc, item) => {
        if (item.columnField === "citationNumber") {
          return { ...acc, citation_number: { _eq: item.value } };
        }

        if (item.columnField === "licensePlate") {
          return { ...acc, session_transaction: { registered_vehicle: { license_plate: { _eq: item.value } } } };
        }

        if (item.columnField === "status") {
          switch (item.value) {
            case "Paid":
              return { ...acc, paid_at: { _is_null: false } };
            case "Unpaid":
              return { ...acc, paid_at: { _is_null: true }, voided_at: { _is_null: true } };
            case "Void":
              return { ...acc, voided_at: { _is_null: false } };
            case "Overdue":
              return {
                ...acc,
                voided_at: { _is_null: true },
                paid_at: { _is_null: true },
                due_at: { _lt: new Date() },
              };
            case "Sent to Collections":
              return { ...acc, sent_to_collections_at: { _is_null: false } };
            default:
              return acc;
          }
        }

        if (item.columnField === "violationType") {
          return { ...acc, violation_type: { _eq: item.value } };
        }

        return acc;
      },
      { notice_sent_at: { _is_null: false } } as GetCitationsWhereFilter,
    );
    setFilter(newFilter);
  }, []);

  const { data, loading, error, refetch } = useQuery<GetCitationsData, GetCitationsVars>(GET_CITATIONS, {
    variables: {
      offset,
      limit,
      orderBy: parseOrderBy(sortModel),
      filter,
    },
    onError(err) {
      console.error("failed to fetch citations", err);
    },
  });
  const exportFileName = `citations_${(Math.random() * 1e6).toFixed(0)}`;

  return (
    <Paper sx={{ borderRadius: 3, maxWidth: 1084, height: 876 }}>
      <DataGridPro
        components={{
          ErrorOverlay: TableErrorOverlay,
          LoadingOverlay: TableLoadingOverlay,
          Toolbar: CitationTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            exportFileName: exportFileName,
          },
          errorOverlay: {
            onTryAgain: () => refetch(),
          },
          loadingOverlay: {
            numColumns: CITATION_TABLE_COLUMNS.length,
          },
        }}
        columns={CITATION_TABLE_COLUMNS}
        rows={data?.citations || []}
        rowCount={data?.citationsAggregate.aggregate.count || 0}
        loading={loading}
        disableMultipleColumnsSorting
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        error={error}
        pagination
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        paginationMode={"server"}
        rowsPerPageOptions={[50, 100]}
        sortingMode={"server"}
        sortModel={sortModel}
        filterMode={"server"}
        onFilterModelChange={onFilterChange}
        initialState={{
          sorting: {
            sortModel: [{ field: "noticeDate", sort: "desc" }],
          },
        }}
        onSortModelChange={onSortModelChange}
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: 0.17,
          },
        }}
      />
    </Paper>
  );
};

export interface CitationTableProps {
  page: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onNavigate: (id: string) => void;
  sortModel: GridSortModel;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

export default Citations;
