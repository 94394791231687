import React from "react";
import { useLogout } from "@app.automotus.io/components/auth";

export const Logout: React.FC = () => {
  const logout = useLogout();

  return (
    <div>
      <button onClick={() => logout()} />
    </div>
  );
};
