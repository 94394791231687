export const violationTypes = [
  { value: "double_park", label: "Double park" },
  { value: "bike_lane", label: "Bike lane" },
  { value: "no_stopping", label: "No stopping" },
  { value: "unregistered_vehicle", label: "Unregistered Vehicle" },
  { value: "exceeds_max_time", label: "Dwell time" },
];

const violationTypeLabelsByValue = violationTypes.reduce((acc, violationType) => {
  acc[violationType.value] = violationType.label;
  return acc;
}, {} as Record<string, string>);

export const getViolationTypeLabel = (violationType: string): string => {
  return violationTypeLabelsByValue[violationType] || violationType;
};
