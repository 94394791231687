import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";

export const OnboardingInformation: React.FC<{
  title: string;
  subTitle?: string;
  content?: string;
  subContent?: string;
}> = ({ title, subTitle, content, subContent }) => (
  <Box>
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flex="1">
      <Box display="flex" flexDirection="row" alignItems="center">
        <CheckCircleOutline sx={{ width: 20, height: 20, p: "2px", mr: "10px" }} />
        <Typography variant={"body1"}>{title}</Typography>
      </Box>
      <Box>
        <Typography variant={"body3"} sx={{ fontWeight: 500, textAlign: "right" }}>
          {content || "\u00A0"}
        </Typography>
      </Box>
    </Box>
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flex="1">
      <Box sx={{ ml: "30px" }}>
        <Typography variant={"caption"} color="text.secondary">
          {subTitle || "\u00A0"}
        </Typography>
      </Box>
      <Box>
        <Typography variant={"caption"} color="text.secondary" sx={{ textAlign: "right" }}>
          {subContent || "\u00A0"}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default OnboardingInformation;
