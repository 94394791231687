import React from "react";
import { StepItem } from "../HowItWorksStepItem";
import setupIconSvg from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks/SetupIcon.svg";
import parkIconSvg from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks/ParkIcon.svg";
import cameraIconSvg from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks/CameraIcon.svg";

export const HowItWorksAirportSteps: React.FC = () => (
  <>
    <StepItem
      iconSrc={setupIconSvg}
      description="Non-tenant ground transportation providers will register a list of vehicles in their fleet to operate at GSP."
    />
    <StepItem iconSrc={parkIconSvg} description="Registered vehicles get instant access to commercial lanes." />
    <StepItem
      iconSrc={cameraIconSvg}
      description="License plate readers read your license plate to determine your activity each month."
    />
  </>
);

export default HowItWorksAirportSteps;
