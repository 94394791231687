import React from "react";
import { StepItem } from "../HowItWorksStepItem";
import setupIconSvg from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks/SetupIcon.svg";
import parkIconSvg from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks/ParkIcon.svg";
import cameraIconSvg from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks/CameraIcon.svg";

export const HowItWorksCitySteps: React.FC = () => (
  <>
    <StepItem
      iconSrc={setupIconSvg}
      description="Register your vehicle and set up a CurbPass using a card, Apple Pay or Google Pay"
    />
    <StepItem
      iconSrc={parkIconSvg}
      description="Get instant access to every Smart Loading Zone without logging in every time you park"
    />
    <StepItem
      iconSrc={cameraIconSvg}
      description="License plate readers read your license plate and auto charge your account only for the time you use"
    />
  </>
);

export default HowItWorksCitySteps;
