import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link, { LinkProps } from "@mui/material/Link";
import React from "react";
import BottomSheet from "@app.automotus.io/components/BottomSheet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

/**
 * Dialog that appears when a user clicks on the "Terms and Conditions" link on the Partner Landing Page.
 */
export const AirportTermsOfServiceDialog: React.FC<AirportTermsOfServiceDialogProps> = ({
  open,
  onClickAgree,
  LinkProps,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const Container = isMobile ? MobileContainer : DesktopContainer;

  return (
    <Container
      open={open}
      header={<Typography variant="h4">Terms and Conditions</Typography>}
      content={
        <Typography variant="body1">
          To register for or log in to your CurbPass account, please review and accept the airport’s{" "}
          <Link {...LinkProps}>Terms and Conditions</Link>.
        </Typography>
      }
      button={
        <Button sx={{ textTransform: "none" }} variant="contained" fullWidth onClick={onClickAgree}>
          I Agree to the Terms and Conditions
        </Button>
      }
    />
  );
};

const DesktopContainer: React.FC<ContainerProps> = ({ open, header, content, button }) => (
  <Dialog PaperProps={{ sx: { p: 4, borderRadius: 3, maxWidth: "390px" } }} open={open}>
    <Stack spacing={4}>
      {header}
      {content}
      {button}
    </Stack>
  </Dialog>
);

const MobileContainer: React.FC<ContainerProps> = ({ open, header, content, button }) => (
  <BottomSheet open={open}>
    <Box
      sx={{ pt: 1.5, px: 2, pb: { xs: 5, tiny: 7 } }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Stack spacing={2}>
        {header}
        {content}
      </Stack>
      {button}
    </Box>
  </BottomSheet>
);

interface ContainerProps {
  open: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
  button: React.ReactNode;
}

/**
 * Props for the PartnerLandingPageAirportTermsOfServiceDialog component.
 */
export interface AirportTermsOfServiceDialogProps<D extends React.ElementType = "a"> {
  /**
   * Whether the dialog is open.
   */
  open: boolean;
  /**
   * Callback function that is called when the user clicks the "I Agree" button.
   */
  onClickAgree: () => void;
  /**
   * Properties of the Link component that points to the Terms and Conditions page.
   */
  LinkProps: LinkProps<D>;
}

export default AirportTermsOfServiceDialog;
