import { useAuth0 } from "@auth0/auth0-react";
import { useApolloClient } from "@apollo/client";

export function useLogout(): LogoutFunc {
  const { logout: auth0Logout } = useAuth0();
  const client = useApolloClient();

  return async () => {
    client.stop();
    await client.resetStore();
    localStorage.removeItem("activePayeeAccountId");
    auth0Logout({ returnTo: `${window.location.origin}/login` });
  };
}

export type LogoutFunc = () => Promise<void>;

export default useLogout;
