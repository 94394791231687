import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledImg = styled("img")({});

export const StepItem: React.FC<HowItWorksStepItemProps> = ({ iconSrc, description }) => (
  <Stack direction="row" spacing={3} alignItems="center">
    <StyledImg src={iconSrc} />
    <Box>
      <Typography variant={"body1"}>{description}</Typography>
    </Box>
  </Stack>
);

export interface HowItWorksStepItemProps {
  iconSrc: string;
  description: string;
}

export default StepItem;
