import { gql } from "@apollo/client";

export const citationSummaryFragment = gql`
  fragment citationSummaryFragment on enforcement_citation {
    id
    index
    citationNumber: citation_number
    violationType: violation_type
    noticeSentAt: notice_sent_at
    paidAt: paid_at
    voidedAt: voided_at
    dueAt: due_at
    totalAmountDue: total_amount_due
    dispute {
      status
    }
    sessionTransaction: session_transaction {
      registeredVehicle: registered_vehicle {
        licensePlateNumber: license_plate
        licensePlateStateCode: state_code
      }
    }
  }
`;

export interface CitationSummary {
  id: string;
  index: number;
  citationNumber: string;
  violationType: string;
  noticeSentAt: string;
  paidAt: string;
  voidedAt: string;
  dueAt: string;
  totalAmountDue?: number;
  sessionTransaction: {
    registeredVehicle: {
      licensePlateNumber: string;
      licensePlateStateCode: string;
    };
  };
  dispute?: {
    status: string;
  };
}

export default citationSummaryFragment;
