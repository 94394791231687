import { GridSortModel, GridSortDirection, GridFilterModel, GridLinkOperator } from "@mui/x-data-grid-pro";

export const parseSortModel = (sortStr: string): GridSortModel => {
  return sortStr
    .split(",")
    .map((columnSpecStr) => columnSpecStr.split("+"))
    .map(([column, direction]) => ({
      field: column,
      sort: direction as GridSortDirection,
    }));
};

export const serializeSortModel = (sortModel: GridSortModel): string => {
  return sortModel.map(({ field, sort }) => `${field}+${sort}`).join(",");
};

export type OrderBy = "asc" | "desc" | "asc_nulls_first" | "asc_nulls_last" | "desc_nulls_first" | "desc_nulls_last";

export const parseFilterModel = (filterStr: string): GridFilterModel => {
  return {
    items: filterStr
      .split(",")
      .map((columnSpecStr) => columnSpecStr.split(":"))
      .map(([columnField, operatorValue, value]) => ({
        columnField,
        operatorValue,
        value,
      })),
    linkOperator: GridLinkOperator.And,
  };
};

export const serializeFilterModel = (filterModel: GridFilterModel): string => {
  return filterModel.items
    .map(({ columnField, operatorValue, value }) => `${columnField}:${operatorValue}:${value}`)
    .join(",");
};
