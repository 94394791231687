import React from "react";
import HowItWorksCitySteps from "./HowItWorksCitySteps";
import HowItWorksAirportSteps from "./HowItWorksAirportSteps";
import Stack from "@mui/material/Stack";

export const HowItWorksSteps: React.FC<HowItWorksStepsProps> = ({ variant }) => (
  <Stack spacing={2}>{variant === "city" ? <HowItWorksCitySteps /> : <HowItWorksAirportSteps />}</Stack>
);

export interface HowItWorksStepsProps {
  variant: "city" | "airport";
}

export default HowItWorksSteps;
