import React, { useState } from "react";
import { ProfileSection } from "@app.automotus.io/components/scenes/Account/ProfileSection";
import { PaymentSection } from "@app.automotus.io/components/scenes/Account/PaymentSection";
import { VehicleSection } from "@app.automotus.io/components/scenes/Account/VehicleSection";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { CREATE_CLOSE_ACCOUNT_REQUEST, CreateCloseAccountRequestData } from "common/graphql";
import { useActivePayee, useUserProfile } from "@app.automotus.io/components/hooks";
import { CloseAccountRequestModal } from "@app.automotus.io/components/modals/CloseAccountRequestModal";
import { BottomSheet } from "@app.automotus.io/components/BottomSheet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import LinkButton from "@app.automotus.io/components/LinkButton";
import Grid from "@mui/material/Grid";
import TermsOfService from "@app.automotus.io/components/TermsOfService";
import Button from "@mui/material/Button";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import CurbPassSettingsDialog from "../scenes/Account/PaymentSection/CurbPassSettingsDialog/CurbPassSettingsDialog";
import Link from "@mui/material/Link";

export const Account: React.FC = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [closeAccountDialogOpen, setCloseAccountDialogOpen] = useState(false);
  const [curbPassSettingsDialogOpen, setCurbPassSettingsDialogOpen] = useState(false);
  const [termsOfServiceDialogOpen, setTermsOfServiceDialogOpen] = useState(false);
  const [refundRequestDialogOpen, setRefundRequestDialogOpen] = useState(false);
  const { userProfile, loading: userProfileLoading } = useUserProfile();
  const { activePayee } = useActivePayee();
  const [closeAccount, { loading: closeAccountLoading }] =
    useMutation<CreateCloseAccountRequestData>(CREATE_CLOSE_ACCOUNT_REQUEST);
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("sm"));
  const { publishSnackbarMessage } = useSnackPackContext();

  const isAirportAccount =
    activePayee?.payeeAccountId && activePayee.payeeAccountId === process.env.REACT_APP_GSP_AIRPORT_ACCOUNT_ID;

  const handleClickCloseAccount = () => {
    setCloseAccountDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setCloseAccountDialogOpen(false);
  };

  const handleCloseCurbPassSettingsDialog = () => {
    setCurbPassSettingsDialogOpen(false);
  };

  const handleCloseTermsOfServiceDialog = () => {
    setTermsOfServiceDialogOpen(false);
  };

  const handleClickTermsOfService = () => {
    setTermsOfServiceDialogOpen(true);
  };

  const showRefundRequestDialog = () => {
    handleCloseDialog();
    setRefundRequestDialogOpen(true);
  };

  const showCurbPassSettingsDialogOpen = () => {
    handleCloseDialog();
    setCurbPassSettingsDialogOpen(true);
  };

  const submitCloseAccountRequest = async (needCustomerSupport = false) => {
    try {
      await closeAccount();
      handleCloseDialog();
      if (needCustomerSupport) {
        publishSnackbarMessage({
          message: "Your request has been sent. An agent will reach out to you shortly.",
          severity: "black",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      } else {
        publishSnackbarMessage({
          message: "Close Account request sent.",
          severity: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    } catch (err) {
      console.error(err, "failed to close account");
      publishSnackbarMessage({ message: "Unable to submit request. Please try again.", severity: "error" });
    }
  };

  return (
    <Container maxWidth={"lg"} sx={{ pb: { lg: 14 } }}>
      <Box sx={{ pt: 9 }}>
        <Box component="header">
          <Typography variant="h6" sx={{ mb: { xs: 1, sm: 2 }, fontWeight: 300 }}>
            Account
          </Typography>
        </Box>
        <ProfileSection onClickCloseAccount={handleClickCloseAccount} />
        {userProfile?.account?.roleType === "payer" && (
          <>
            <PaymentSection
              refundRequestDialogOpen={refundRequestDialogOpen}
              setRefundRequestDialogOpen={setRefundRequestDialogOpen}
            />
            <VehicleSection />

            <Grid container sx={{ pt: 2, pb: { xs: 3, lg: 6 } }} columnSpacing={2.5} rowSpacing={4}>
              <Grid item xs={12} lg={2} />
              <Grid item xs={12} lg={10}>
                {isAirportAccount ? (
                  <Box sx={{ margin: "auto", display: "flex" }}>
                    <Typography sx={{ margin: "auto", display: "flex" }} variant="body1">
                      <Link
                        target="_blank"
                        href={`${process.env.REACT_APP_ASSET_ENDPOINT}/documents/gsp-airport-terms-and-conditions.pdf`}
                      >
                        Terms of Service
                      </Link>
                    </Typography>
                  </Box>
                ) : (
                  <LinkButton
                    variant="body1"
                    type="button"
                    onClick={handleClickTermsOfService}
                    sx={{ margin: "auto", display: "flex" }}
                  >
                    Terms of Service
                  </LinkButton>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {!isMobile && (
        <Dialog
          open={closeAccountDialogOpen}
          fullScreen={fullScreenDialog}
          onBackdropClick={handleCloseDialog}
          PaperProps={{
            sx: { borderRadius: fullScreenDialog ? 0 : 1.5, minWidth: { lg: 454 }, p: 4 },
          }}
        >
          <CloseAccountRequestModal
            loading={userProfileLoading}
            submittingCloseRequest={closeAccountLoading}
            onSendCloseRequest={submitCloseAccountRequest}
            onKeepAccount={handleCloseDialog}
            onShowRefundRequestDialog={showRefundRequestDialog}
            onShowCurbPassSettingsDialogOpen={showCurbPassSettingsDialogOpen}
            title="Close Account Request"
          />
        </Dialog>
      )}
      {isMobile && (
        <BottomSheet open={closeAccountDialogOpen} onClose={handleCloseDialog} marginTopSmall={56} marginTopMedium={56}>
          <AppBar
            sx={{
              height: "52px",
              position: "relative",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <Box>
                <Typography variant="body3" sx={{ fontWeight: 500, ml: 1 }}>
                  Close Account Request
                </Typography>
              </Box>
              <Box>
                <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "100px",
                      backgroundColor: "rgba(0, 0, 0, 0.135)",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "14px" }} />
                  </Box>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ px: 3, pt: 0 }}>
            <CloseAccountRequestModal
              loading={userProfileLoading}
              submittingCloseRequest={closeAccountLoading}
              onSendCloseRequest={submitCloseAccountRequest}
              onKeepAccount={handleCloseDialog}
              onShowRefundRequestDialog={showRefundRequestDialog}
              onShowCurbPassSettingsDialogOpen={showCurbPassSettingsDialogOpen}
            />
          </DialogContent>
        </BottomSheet>
      )}
      <CurbPassSettingsDialog
        open={curbPassSettingsDialogOpen}
        onClose={handleCloseCurbPassSettingsDialog}
        payeeAccountId={activePayee?.payeeAccountId || ""}
        loading={userProfileLoading}
      />
      <Dialog
        open={termsOfServiceDialogOpen}
        fullScreen={fullScreenDialog}
        onBackdropClick={handleCloseTermsOfServiceDialog}
        PaperProps={{
          sx: {
            borderRadius: fullScreenDialog ? 0 : 1.5,
            minWidth: { lg: 572 },
            px: 2,
            pb: 4,
            pt: 2,
            display: "flex",
            mt: isMobile ? "200px" : 0,
            height: isMobile ? "calc(100% - 200px)" : "auto",
          },
        }}
      >
        <Box sx={{ flex: 1, overflowY: "scroll" }}>
          <TermsOfService />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button variant="contained" onClick={handleCloseTermsOfServiceDialog} fullWidth>
            Close
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
};
