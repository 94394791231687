import { gql } from "@apollo/client";
import { OrderBy } from "common/graphql/queryString";

export const GET_INVOICES = gql`
  query GetInvoices(
    $limit: Int!
    $offset: Int!
    $orderBy: [financial_invoice_order_by!]
    $filter: financial_invoice_bool_exp = { notice_sent_at: { _is_null: false } }
  ) {
    invoices: financial_invoice(where: $filter, limit: $limit, offset: $offset, order_by: $orderBy) {
      adminFeeDue: admin_fee_due
      noticeSentAt: notice_sent_at
      dueAt: due_at
      voidedAt: voided_at
      id
      index
      dispute {
        id
        status
      }
      adminFeeAmountWaived: admin_fee_amount_waived
      registeredVehicle: registered_vehicle {
        id
        licensePlateNumber: license_plate
        licensePlateStateCode: state_code
      }
      totalAmountDue: total_amount_due
      transactionAmountDue: transaction_amount_due
      paymentTransaction: payment_transaction {
        id
        createdAt: created_at
      }
      replacedByInvoice: replaced_by_invoice {
        id
        index
      }
    }
    invoicesAggregate: financial_invoice_aggregate(where: { notice_sent_at: { _is_null: false } }) {
      aggregate {
        count
        sum {
          amountPaid: amount_paid
        }
      }
    }
  }
`;

export interface GetInvoicesWhereFilter {
  notice_sent_at: { _is_null: false };
  index?: { _eq: number };
  registered_vehicle?: { license_plate: { _eq: string } };
  _not?: {
    payment_transaction: Record<string, never>;
  };
  payment_transaction?: Record<string, never>;
  dispute?: { status: { _eq: "pending" } };
  voided_at?: { _is_null: boolean };
  due_at?: { _lt: string };
}
export interface GetInvoicesVars {
  limit: number;
  offset: number;
  orderBy?: InvoiceOrderBy[];
  filter?: GetInvoicesWhereFilter;
}

export type InvoiceOrderBy = InvoiceOrderByIndex | InvoiceOrderByNoticeDate | InvoiceOrderByPaidDate;

export interface InvoiceOrderByIndex {
  index: OrderBy;
}

export interface InvoiceOrderByNoticeDate {
  notice_sent_at: OrderBy;
}

export interface InvoiceOrderByPaidDate {
  payment_transaction: {
    created_at: OrderBy;
  };
}

// TODO: add computed field to enable proper ordering by total amount due

export interface GetInvoicesData {
  invoices: InvoiceSummary[];
  invoicesAggregate: {
    aggregate: {
      count: number;
      sum: {
        amountPaid: number;
      };
    };
  };
}

export interface InvoiceSummary {
  adminFeeDue: number;
  noticeSentAt: string;
  dueAt: string;
  voidedAt: string;
  dispute: {
    id: string;
    status: "approved" | "denied" | "pending";
  };
  id: string;
  index: number;
  adminFeeAmountWaived: number;
  registeredVehicle: {
    id: string;
    licensePlateNumber: string;
    licensePlateStateCode: string;
  };
  totalAmountDue: number;
  transactionAmountDue: number;
  paymentTransaction: {
    id: string;
    createdAt: string;
  } | null;
  replacedByInvoice: {
    id: string;
    index: number;
  } | null;
}
