import { useMemo } from "react";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { useQuery } from "@apollo/client";
import COUNT_CURRENTLY_REGISTERED_VEHICLES, {
  CountCurrentlyRegisteredVehiclesData,
} from "common/graphql/queries/CountCurrentlyRegisteredVehicles";
import { SignUpStepValue } from "@app.automotus.io/components/scenes/SignUp/SignUpStepper/SignUpStepper";
import { GET_PAYMENT_METHODS, GetPaymentMethodsData, UserProfile } from "common/graphql";

export interface UseProfileWithCompletedStepsResult {
  userProfile?: UserProfile;
  loading: boolean;
  completedSteps: SignUpStepValue[];
}

export function useProfileWithCompletedSteps(): UseProfileWithCompletedStepsResult {
  const { userProfile, loading } = useUserProfile();
  const { data: countCurrentlyRegisteredVehicles, loading: registeredVehiclesLoading } =
    useQuery<CountCurrentlyRegisteredVehiclesData>(COUNT_CURRENTLY_REGISTERED_VEHICLES);
  const { data: paymentMethodsData, loading: paymentMethodsLoading } =
    useQuery<GetPaymentMethodsData>(GET_PAYMENT_METHODS);
  const isProfileComplete =
    userProfile?.email && userProfile?.phoneNumber && userProfile?.fullName && userProfile?.selectedCompanies;
  const isPaymentComplete =
    (userProfile?.account.wallets.length || 0) > 0 && (paymentMethodsData?.paymentMethods?.length || 0) > 0;
  const isVehiclesComplete = (countCurrentlyRegisteredVehicles?.registeredVehicleCount.aggregate.count || 0) > 0;

  const completedSteps: SignUpStepValue[] = useMemo(
    () => [
      ...(isProfileComplete ? ["profile" as const] : []),
      ...(isVehiclesComplete ? ["vehicle" as const] : []),
      ...(isPaymentComplete ? ["payment" as const] : []),
    ],
    [isPaymentComplete, isVehiclesComplete, isProfileComplete],
  );

  return {
    userProfile,
    loading: loading || registeredVehiclesLoading || paymentMethodsLoading,
    completedSteps,
  };
}

export default useProfileWithCompletedSteps;
