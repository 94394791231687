import { gql } from "@apollo/client";
import { InvoiceData, invoiceFragment } from "../fragments/invoiceFragment";

export const PAY_INVOICE = gql`
  mutation PayInvoice($invoiceId: uuid!, $paymentMethodId: uuid, $paymentMethodDetails: payment_method_details) {
    payInvoice: pay_invoice(
      invoice_id: $invoiceId
      payment_method_id: $paymentMethodId
      payment_method_details: $paymentMethodDetails
    ) {
      invoiceId: invoice_id
      transactionId: transaction_id
      invoice {
        ...invoiceFragment
      }
    }
  }
  ${invoiceFragment}
`;

export interface PayInvoiceVars {
  invoiceId: string;
  paymentMethodId?: string;
  paymentMethodDetails?: {
    gateway_access: unknown;
    billing_details: {
      zip: string;
    };
  };
}

export interface PayInvoiceData {
  payInvoice: {
    invoiceId: string;
    transactionId: string;
    invoice: InvoiceData;
  };
}
