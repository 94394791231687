import React, { useRef, useState } from "react";
import { PartnerLandingPageHowItWorksModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LogoBlue from "@app.automotus.io/components/images/AppLogo/logoBlue";
import { DesktopNavBarMenu } from "@app.automotus.io/components/NavBar/NavBarMenu";
import { useActivePayee } from "@app.automotus.io/components/hooks";
import { Theme } from "@mui/material/styles";

export const PartnerLandingPageDesktopNavbar: React.FC<NavbarProps> = ({ openParkingInfoModal }) => {
  const toolbarRef = useRef(null);
  const [howItWorksModalOpen, setHowItWorksModalOpen] = useState(false);

  const handleOpenHowItWorksModal = () => {
    setHowItWorksModalOpen(true);
  };

  const handleCloseHowItWorksModal = () => {
    setHowItWorksModalOpen(false);
  };

  const { activePayee } = useActivePayee();
  const isAirportAccount = activePayee?.payeeAccountId === process.env.REACT_APP_GSP_AIRPORT_ACCOUNT_ID;

  const NAV_LINKS = [
    ...(isAirportAccount
      ? []
      : [
          {
            title: "Zone Regulations",
            to: "/",
            onClick: openParkingInfoModal,
          },
          {
            title: "How it Works",
            to: "/",
            onClick: handleOpenHowItWorksModal,
          },
        ]),
    {
      title: "Support",
      to: "/support",
      onClick: () => {
        window.open("/support", "_blank");
      },
    },
  ];

  return (
    <AppBar
      position={"sticky"}
      sx={{
        bgcolor: (theme: Theme) => theme.palette.background.paper,
        color: (theme: Theme) => theme.palette.text.primary,
      }}
    >
      <Toolbar
        sx={{
          height: { xs: 54, lg: 88 },
          px: { xs: 2, md: 5 },
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        ref={toolbarRef}
      >
        <LogoBlue sx={{ height: { xs: 22, lg: 24 }, width: "auto" }} />
        <DesktopNavBarMenu targets={NAV_LINKS} />
      </Toolbar>
      <PartnerLandingPageHowItWorksModal open={howItWorksModalOpen} onClose={handleCloseHowItWorksModal} />
    </AppBar>
  );
};

export interface ParkingRates {
  currency: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervals: any;
}
export interface NavbarProps {
  openParkingInfoModal: () => void;
}

export default PartnerLandingPageDesktopNavbar;
